import { FunctionComponent, useState } from "react";
import { useModal } from "_hooks";
import { ToggleButton } from "_components";
import { Stock } from "_api";
import "./table.scss";
import { useRecoilValue } from "recoil";
import { atomUserRole } from "_atoms";

type StockItemProps = {
  stock: Stock;
  setStockInfoModal: (stock: Stock | null) => void;
  setClientInfoModal: (stock: Stock | null) => void;
  setPointOfSaleModal: (stock: Stock | null) => void;
  onSelectedCUNOChange: (newCUNO: string) => void;
};

const StockItem: FunctionComponent<StockItemProps> = ({
  stock,
  setStockInfoModal,
  setClientInfoModal,
  setPointOfSaleModal,
  onSelectedCUNOChange,
}) => {
  const { setModal } = useModal();
  const [isToggled, setIsToggled] = useState(stock.stockExchange);

  const userRole = useRecoilValue(atomUserRole);

  const isUserTrigano = userRole?.name === "User-trigano";
  const isAgentLimite = userRole?.name === "Agent-Limite";

  const handleClickStock = () => {
    setIsToggled(!isToggled);

    if (!isToggled) {
      setModal(true);
      setStockInfoModal(stock);
    } else {
      null;
    }
  };

  const handleChangePointOfSale = () => {
    onSelectedCUNOChange(stock.CUNO);
    console.log("CUNO",stock.CUNO)
    setPointOfSaleModal(stock);
  };

  return (
    <div className="table__line">
      <div className="table__sticky">
        <div className="table__group">
          <div
            className={`table__item final-client ${
              isUserTrigano ? "disabled" : ""
            }`}
            onClick={() => !isUserTrigano && setClientInfoModal(stock)}
          ></div>
          <div className="table__item stock-exchange">
            <ToggleButton
              toggled={isToggled}
              handlechange={handleClickStock}
              disabled={isUserTrigano || isAgentLimite}
            />
          </div>
        </div>
      </div>
      <div className="table__group">
        <div className="table__item type">{stock.type}</div>
        <div className="table__item dealer-location">
          {stock.point_of_sale && stock.point_of_sale.name && (
            <>
              {stock.point_of_sale.name}
              <span
                className="edit"
                onClick={() => handleChangePointOfSale()}
              ></span>
            </>
          )}
          {(!stock.point_of_sale || !stock.point_of_sale.name) && (
            <span
              className="edit"
              onClick={() => handleChangePointOfSale()}
            ></span>
          )}
        </div>
        <div className="table__item brand">{stock.brand}</div>
        <div className="table__item model">{stock.model}</div>
        <div className="table__item year">{stock.millesime_vehicle}</div>
        <div className="table__item carrier">{stock.carrier}</div>
        <div className="table__item order-number">{stock.ORNO}</div>
        <div className="table__item bano">{stock.VIN}</div>
        <div className="table__item billing-date">
          {new Date(stock.billingDate).toLocaleDateString()}
        </div>
      </div>
    </div>
  );
};

export default StockItem;
