import React, { FunctionComponent, useMemo, useState } from "react";
import { get } from "lodash";
import StockItem from "./StockItem"; // Make sure to correctly import StockItem
import { Stock } from "_api";
import { useTranslation } from "react-i18next";

type StockTableProps = {
  stockProducts: Stock[];
  setStockInfoModal: (stock: Stock | null) => void;
  setClientInfoModal: (stock: Stock | null) => void;
  setPointOfSaleModal: (stock: Stock | null) => void;
  onSelectedCUNOChange: (newCUNO: string) => void;
};

const StockTable: FunctionComponent<StockTableProps> = ({
  stockProducts,
  setStockInfoModal,
  setClientInfoModal,
  setPointOfSaleModal,
  onSelectedCUNOChange,
}) => {
  const { t } = useTranslation();
  const [sortConfig, setSortConfig] = useState<{
    key: string | null;
    direction: string;
  }>({
    key: null,
    direction: "ascending",
  });

  console.log(stockProducts);

  const sortedStockProducts = useMemo(() => {
    const sortableItems = [...stockProducts];
    if (sortConfig.key) {
      const key = sortConfig.key;
      sortableItems.sort((a, b) => {
        let aValue;
        let bValue;
        aValue = get(a, key);
        bValue = get(b, key);

        if (typeof aValue === "string") aValue = aValue.toLowerCase();
        if (typeof bValue === "string") bValue = bValue.toLowerCase();

        const aIsEmpty =
          aValue === null || aValue === undefined || aValue === "";
        const bIsEmpty =
          bValue === null || bValue === undefined || bValue === "";

        if (aIsEmpty && bIsEmpty) return 0;
        if (aIsEmpty) return 1;
        if (bIsEmpty) return -1;

        if (aValue < bValue)
          return sortConfig.direction === "ascending" ? -1 : 1;
        if (aValue > bValue)
          return sortConfig.direction === "ascending" ? 1 : -1;
        return 0;
      });
    }
    return sortableItems;
  }, [stockProducts, sortConfig]);

  const requestSort = (key: string) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  return (
    <div className="page-container">
      <div className="table">
        <div className="table__header">
          <div className="table__sticky">
            <div className="table__group">
              <div className="table__title final-client">
                {t("final_client")}
              </div>
              <div className="table__title stock-exchange">
                {t("stock_exchange")}
              </div>
            </div>
          </div>
          <div className="table__group">
            <div
              className={`table__title type ${
                sortConfig.key === "type"
                  ? sortConfig.direction === "ascending"
                    ? "table__title--asc"
                    : "table__title--desc"
                  : ""
              } table__title--sortable`}
              onClick={() => {
                requestSort("type");
              }}
            >
              {t("type")}
            </div>
            <div
              className={`table__title dealer-location ${
                sortConfig.key === "point_of_sale.dealer"
                  ? sortConfig.direction === "ascending"
                    ? "table__title--asc"
                    : "table__title--desc"
                  : ""
              } table__title--sortable`}
              onClick={() => {
                requestSort("point_of_sale.name");
              }}
            >
              {t("dealer_name_location_short")}
            </div>
            <div
              className={`table__title brand ${
                sortConfig.key === "brand"
                  ? sortConfig.direction === "ascending"
                    ? "table__title--asc"
                    : "table__title--desc"
                  : ""
              } table__title--sortable`}
              onClick={() => {
                requestSort("brand");
              }}
            >
              {t("brand")}
            </div>
            <div
              className={`table__title model ${
                sortConfig.key === "model"
                  ? sortConfig.direction === "ascending"
                    ? "table__title--asc"
                    : "table__title--desc"
                  : ""
              } table__title--sortable`}
              onClick={() => {
                requestSort("model");
              }}
            >
              {t("model")}
            </div>
            <div
              className={`table__title year ${
                sortConfig.key === "millesime_vehicle"
                  ? sortConfig.direction === "ascending"
                    ? "table__title--asc"
                    : "table__title--desc"
                  : ""
              } table__title--sortable`}
              onClick={() => {
                requestSort("millesime_vehicle");
              }}
            >
              {t("year")}
            </div>
            <div
              className={`table__title carrier ${
                sortConfig.key === "carrier"
                  ? sortConfig.direction === "ascending"
                    ? "table__title--asc"
                    : "table__title--desc"
                  : ""
              } table__title--sortable`}
              onClick={() => {
                requestSort("carrier");
              }}
            >
              {t("carrier")}
            </div>
            <div
              className={`table__title order-number ${
                sortConfig.key === "ORNO"
                  ? sortConfig.direction === "ascending"
                    ? "table__title--asc"
                    : "table__title--desc"
                  : ""
              } table__title--sortable`}
              onClick={() => {
                requestSort("ORNO");
              }}
            >
              {t("order_number")}
            </div>
            <div
              className={`table__title bano ${
                sortConfig.key === "VIN"
                  ? sortConfig.direction === "ascending"
                    ? "table__title--asc"
                    : "table__title--desc"
                  : ""
              } table__title--sortable`}
              onClick={() => {
                requestSort("VIN");
              }}
            >
              {t("bano")}
            </div>
            <div
              className={`table__title billing-date ${
                sortConfig.key === "billingDate"
                  ? sortConfig.direction === "ascending"
                    ? "table__title--asc"
                    : "table__title--desc"
                  : ""
              } table__title--sortable`}
              onClick={() => {
                requestSort("billingDate");
              }}
            >
              {t("billing_date")}
            </div>
          </div>
        </div>

        {sortedStockProducts.map((stockproduct, index) => (
          <StockItem
            key={stockproduct.invoiceNumber || index}
            stock={stockproduct}
            setStockInfoModal={setStockInfoModal}
            setClientInfoModal={setClientInfoModal}
            setPointOfSaleModal={setPointOfSaleModal}
            onSelectedCUNOChange={onSelectedCUNOChange}
          />
        ))}
      </div>
    </div>
  );
};

export default StockTable;
